.contact_content{
    display: flex;
    flex-direction: column;
    border: 0.1rem solid var(--secondary);
    border-radius: 1rem;
    padding: 2rem;
}
.contact_icons{
    display: grid;
    gap: 1rem;
    width: 100%;
    align-items: center;
    text-align: center;
    grid-auto-flow:column;
}
.icon_contact{
    width: 2rem;
    height: auto;
    margin: 1rem auto 0 auto;
    animation: socialIcons 2s ease-in-out infinite;
    transition: transform .5s ease;
}
.icon-contact:hover{
    --icon:var(--green-strong) ;
    transform: translateY(-0.3rem);
}
.user{
    color: var(--green-strong);
}
@keyframes socialIcons{
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(0.5rem);
    }
    0% {
        transform: translateY(0);
      }
}
@media only screen and (min-width:30rem) {
    .contact_content{
        flex-direction: row;
        align-items: center; 
        padding: 5rem;       
    }
    .contact_icons{
        width: 60%;
        grid-auto-flow:row;
    }
    .icon_contact{
        width: 3.125rem;
    }
}