@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600&display=swap');
:root{
  --primary:#080617;
  --primary-light:#100C28;
  --primary-dark:#0a091a;
  --primary-transparent:rgba(10, 9, 26,.35);
  --secondary:#b6bff3;
  --secondary-light:rgb(198, 204, 240);
  --green-strong:#05F2DB;
  --green-strong-transparent:rgb(5, 242, 219,0.2);
  --blue:#282f99;
  --blue-transparent:rgba(40, 47, 153,.5);
  --icon:var(--secondary);
  --white:#dbdef0;

}
html{
  box-sizing: border-box;
}
*,*::after,*::before{
  box-sizing: inherit;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  transition: .3s ease-in-out;
}
body{
  font-family: 'Nunito', sans-serif;
  background:
  radial-gradient( var(--blue), var(--primary));

  color: var(--secondary);
  font-weight: 300;
  position: relative;
  width: 90%;
  max-width: 70rem;
  margin: 0 auto;
}
h1{
  color: var(--green-strong);
  font-weight: 400;
  font-size: 4rem;
}
.screen-readers-only{
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}
h2{
  color: transparent;
  -webkit-text-stroke: 0.1rem var(--secondary-light);
  font-size: 3rem;
  margin-bottom: 3rem;
  text-shadow: 0px 1px 20px var(--secondary-light);
}
h3{
  margin-bottom: 1rem;
  color: var(--white);
}
p{
  font-size: 1rem;
}
section{
  padding: 6rem 0;
}

@media only screen and (min-width:30rem) {
    h1{
      font-size: 6rem;
    }
    h2{
      font-size: 4rem;
    }
    p{
      font-size: 1.3rem;
    }
    section{
      padding: 8rem 0;
    }
}