.aboutme_description{
    width: 100%;
    max-width: 40rem;
    margin-bottom: 3rem;
}
strong{
    color: var(--green-strong);
}
.tech_icon{
    width: 1rem;
    height: auto;
    margin-right: 1rem;
}
.tech_list{
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;
    height: 2.5rem;
}