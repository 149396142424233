.project-btns{
    display: flex;
}
.project-btn{
    background-color:var(--blue-transparent);
    padding: 1rem;
    width: 10rem;
    margin-bottom: 2rem;
    color: var(--secondary);
    border: 0.2rem solid transparent;
    margin-right: 0.3rem;
    font-size: 1.3rem;
    font-weight: 300;
    cursor: pointer;
}
.project-btn:hover{
    background-color: var(--transparent);
}
.project-btn-active{
    border-bottom: 0.2rem solid var(--green-strong);
}
.project-box{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 1rem;
}
.project-card{
    height: 28rem;
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: solid 0.05rem var(--secondary);
    padding: 13rem 2rem 3rem 2rem;
    transition: transform .5s ease;
    background: rgba(106, 95, 167, 0.1);
}
.project-card:hover{
    border-radius: 4rem;
}
.project-img{
    position: absolute;
    top: 0;
    left: auto;
    height: auto;
    width: auto;
    opacity: .2;
    z-index: -1;
}
.project-techs{
    color: var(--green-strong);
    font-size: 1rem;
    margin-bottom: 1rem;
}
.project-description{
    color: var(--white);
    font-size: 0.93rem;
}
.project-links{
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;
    margin-top: 1.5rem;
    z-index: 1;
}
.icon-project{
    width: 2.1875rem;
    height: auto;
    margin: 0 auto;
    transition: all .3s ease;
}
.icon-project:hover{
    --icon:var(--green-strong);
}
.project-card:hover .icon-project{
    transform: scale(1.3);
}
.project-card:hover .project-img{
   scale: 1.3;
}
@media only screen and (min-width:55rem) {
    .project-box{
        width: 42rem;
        grid-template-columns: 1fr 1fr;
    }
    .project-card{
        width: 20rem;
    }
    .project-img{
        height: 28rem;
        left: -50%;        
    }
}
@media only screen and (min-width:80rem) {
    .project-box{
        width: 62rem;
        grid-template-columns: 1fr 1fr 1fr;
    }
}